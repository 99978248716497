import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';

import "./PageLogin.css";
import page_content from "./PageLogin.html";

/*
http://localhost:8081/#login?email=landre3567@gmail.com&ws_stage=local&db_stage=local
*/

export class PageLogin extends MSAppPage {

    m_email;
    m_ws_stage;
    m_db_stage;

    constructor(iRef) {
        super(iRef);
    }
    $create(iQuery) {
        // Crée le contenu HTML de la page de login
        const $res = $(page_content);

        // Gestionnaire de clic pour le bouton Google SSO
        $res.find('#google_sso_btn').on('click', () => {
            console.log('g_id_onload:' + $res.find("div.g_id_signin div[role='button']").length);
            $res.find("div.g_id_signin div[role='button']").trigger('click');
        });
        // Gestionnaire de clic pour le bouton de mot de passe oublié
        $res.find('#btn_lost_password').on('click', async () => {
            try {
                await gl_api.sendVerificationEmail(
                    'reset_password',
                    this.m_ws_stage,
                    this.m_db_stage,
                    this.m_email,
                    window.location.origin + '#login?step=reset_password&reason=lost&email={{{email}}}&token={{{token}}}&ws_stage={{{ws_stage}}}&expiration={{{expiration}}}');
                await new MSModal({
                    title: _l('Attente de validation'),
                    html: _h('Un email de confirmation vient de vous être envoyé à l\'adresse {0}.\nVeuillez cliquer sur le lien présent dans l\'email afin de réinitialiser votre mot de passe').format(`<b>${this.m_email}</b>`),
                }).open();

            } catch (e) {
                await gl_api.showError(e);
            } finally {
                gl_app.setHash('#welcome');
            }
        });
        // Vérifie si l'utilisateur est déjà connecté via le navigateur et configure le bouton de SS
        get_browser_logged_user().then((user) => {
            if (user) {
                create_ticketing_sso_btn('ticketing_sso_btn', 'Se connecter en tant que ' + (user.name ? user.name : ''), user.email);
                $res.find("#ticketing_sso_btn").on("click", () => {
                    this.ssoLogin('browser', {
                        browser_id: window.browser_id,
                        email: user.email,
                    });
                });
            }
        });
        // Gère la connexion via Google SSO
        window.onGoogleSignIn = (credentials) => {
            console.log('onGoogleSignIn', credentials);
            this.ssoLogin('google', credentials);
        };

        return $res;
    }
    // Gère les changements dans la requête avant d'ouvrir la page
    onBeforeOpen(iQuery) {
        this.onQueryChanged(iQuery);
    }
    // Met à jour les propriétés de la page en fonction des paramètres de la requête
    onQueryChanged(iQuery) {
        if (iQuery.email) {
            this.m_email = iQuery.email;
        }
        if (iQuery.db_stage) {
            this.m_db_stage = iQuery.db_stage;
        }
        if (iQuery.ws_stage) {
            this.m_ws_stage = iQuery.ws_stage;
        }
        switch (iQuery.step) {
            case 'ask_password':
                this.onStepAskPassword(iQuery);
                break;
            case 'create_password':
            case 'reset_password':
                this.onStepResetPassword(iQuery);
                break;
            default:    //first page
                this.onStepInitial(iQuery);
                break;
        }
    }
    // Gère l'affichage initial de la page de login
    onStepInitial(iQuery) {
        const auth_token = gl_app.authToken();
        if (auth_token && auth_token.user.email == iQuery.email && auth_token.db_stage == iQuery.db_stage) {
            if (gl_api.wsUrl(iQuery.ws_stage) == auth_token.web_service_url) {
                gl_app.setHash('#dashboard');
                return;
            }
        }
        // Configure l'interface utilisateur pour l'étape initiale de login
        this.$m_div.find('#title_login').text(_l('Bienvenue'));
        this.$m_div.find('#sso_bar').show();
        this.$m_div.find('#btn_lost_password').hide();
        this.m_form = new MSForm({
            fields: [
                [
                    {
                        type: 'fl_select',
                        cols: [12, 6, 6],
                        key: 'db_stage',
                        class: 'ms_only',
                        label: "Base de données",
                        options: {
                            'prod': "Production",
                            'test': "Test",
                            'local': "Locale"
                        }
                    }, {
                        type: 'fl_select',
                        cols: [12, 6, 6],
                        key: 'ws_stage',
                        class: 'ms_only',
                        label: "Web Service",
                        options: {
                            'prod': "Production",
                            'beta': "Beta",
                            'local': "Local"
                        }
                    }
                ], {
                    type: 'fl_email',
                    cols: [12, 12, 12],
                    key: 'email',
                    label: _l("Adresse Email"),
                    class: 'log_input',
                    css: {
                        'text-transform': 'lowercase'
                    },
                    validation: {
                        required: true,
                    }
                }
            ]
        });
        const $card_body = this.$m_div.find('#login_form');
        $card_body.html('');
        const $form = this.m_form.create();
        $form.appendTo($card_body);
        // Pré-remplit les valeurs de formulaire avec les paramètres de la requête
        const val = {
            email: iQuery.email,
            db_stage: iQuery.db_stage,
            ws_stage: iQuery.ws_stage,
        };
        if (!gl_app.is_secure_ip || gl_app.stage == 'prod') {
            this.$m_div.find('.ms_only').hide();
        } else {
            this.$m_div.find('.ms_only').show();
            if (localStorage.getItem('db_stage')) {
                val.db_stage ??= localStorage.getItem('db_stage');
                val.ws_stage ??= localStorage.getItem('ws_stage');
            }
        }
        val.db_stage ??= (gl_app.stage == 'beta' ? 'test' : gl_app.stage);
        val.ws_stage ??= gl_app.stage;

        this.m_form.setValue(val);
        const $btn = this.$m_div.find('#btn_submit_login');
        $btn.text(_l('Continuer'));
        this.m_form.on('change', () => {
            const cur_val = this.m_form.getValue();
            localStorage.setItem('db_stage', cur_val.db_stage);
            localStorage.setItem('ws_stage', cur_val.ws_stage);
        });
        this.m_form.trigger('change');
        $btn.off().on('click', async () => {
            const data = this.m_form.getValue();
            this.m_email = data.email;
            this.m_ws_stage = data.ws_stage;
            this.m_db_stage = data.db_stage;
            try {
                const res = await gl_api.getEmailInfo(data.ws_stage, data.db_stage, data.email);
                if (res.email != data.email) {
                    throw new Error("email mismatch");
                }
                if (!res.has_group) {
                    Swal.fire({
                        icon: "error",
                        title: "Utilisateur inconnu",
                        text: "Vous n'êtes pas autorisé à vous connecter à ce site !",
                        customClass: {
                            confirmButton: 'log_standard_btn',
                        }
                    });
                    return;
                }
                if (res.has_password) {
                    gl_app.setHash({
                        hash: "login",
                        step: "ask_password",
                        email: data.email,
                        ws_stage: data.ws_stage,
                        db_stage: data.db_stage,
                    });
                    return;
                }
                if (await MSModal.ask(_l("Avertissement"), _l("Vous n'avez pas de mot de passe.\nVoulez-vous en créer un ?"), [_l('Annuler'), _l('Créer un mot de passe')]) == 1) {
                    await gl_api.sendVerificationEmail(
                        'create_password',
                        data.ws_stage,
                        data.db_stage,
                        data.email,
                        window.location.origin + '#login?step=create_password&email={{{email}}}&token={{{token}}}&ws_stage={{{ws_stage}}}&expiration={{{expiration}}}'
                    );

                    // Ajoute un message d'information après l'envoi de l'e-mail
                    Swal.fire({
                        icon: 'info',
                        title: _l('Merci de vérifier votre boîte mail'),
                        text: _l('Un email de confirmation vous a été envoyé à l\'adresse {0}.').format(data.email),
                        confirmButtonText: _l('OK'),
                        customClass: {
                            confirmButton: 'log_standard_btn'
                        }
                    });
                }
            } catch (e) {
                gl_api.showError(e);
            }
        });
    }

    // Effectue la connexion via Single Sign-On (SSO) avec le type et les paramètres spécifiés
    async ssoLogin(iType, iParams) {
        try {
            const res = await gl_api.ssoLogin(
                localStorage.getItem('ws_stage'),
                localStorage.getItem('db_stage'),
                iType,
                iParams
            );
            gl_app.setAuthToken(res.auth_token);
            gl_app.setHash('#', true);
        } catch (e) {
            await gl_api.showError(e);
        }
    }
    // Vérifie si le mot de passe respecte les règles de sécurité
    verifyPassword(iPassword) {
        if (iPassword.length < 8) {
            return _l("Le mot de passe doit contenir au moins 8 caractères");
        }
        if (! /\d/.test(iPassword)) {
            return _l("Le mot de passe doit contenir au moins un chiffre");
        }
        if (! /[a-z]/.test(iPassword)) {
            return _l("Le mot de passe doit contenir au moins une lettre minuscule");
        }
        if (! /[A-Z]/.test(iPassword)) {
            return _l("Le mot de passe doit contenir au moins une lettre majuscule");
        }
        return "";
    }
    // Gère l'étape de demande de saisie du mot de passe
    onStepAskPassword(iQuery) {
        this.$m_div.find('#title_login').text(_l('Merci de saisir votre mot de passe'));
        this.$m_div.find('#sso_bar').hide();
        this.$m_div.find('#btn_lost_password').show();
        this.m_form = new MSForm({
            fields: [
                {
                    type: 'fl_email',
                    cols: [12, 12, 12],
                    key: 'email',
                    class: 'log_input',
                    label: _l("Adresse Email"),
                    readonly: true,
                    css: {
                        'text-transform': 'lowercase'
                    },
                    validation: {
                        required: true,
                    }
                },
                {
                    type: 'fl_password',
                    cols: [12, 12, 12],
                    class: 'log_input',
                    key: 'password',
                    autofocus: true,
                    label: _l("Mot de passe"),
                }
            ]
        });
        const $form = this.m_form.create();
        this.$m_div.find('#login_form').html('');
        $form.appendTo(this.$m_div.find('#login_form'));

        // Ajouter le bouton après le champ de mot de passe
        const $passwordInput = this.$m_div.find('input[name="password"]');
        const $toggleButton = $('<button type="button" class="btn btn-outline-secondary show-password-btn"><i class="bi bi-eye"></i></button>');

        $toggleButton.insertAfter($passwordInput);

        // Ajouter le gestionnaire d'événement pour le bouton "eye"
        $toggleButton.on('click', () => {
            if ($passwordInput.attr('type') === 'password') {
                $passwordInput.attr('type', 'text');
                $toggleButton.find('i').removeClass('bi-eye').addClass('bi-eye-slash');
            } else {
                $passwordInput.attr('type', 'password');
                $toggleButton.find('i').removeClass('bi-eye-slash').addClass('bi-eye');
            }
        });


        const val = {
            email: iQuery.email,
        };
        this.m_form.setValue(val);
        const $btn = this.$m_div.find('#btn_submit_login');
        $btn.text(_l('valider')).removeClass('disabled');
        $btn.off().on('click', async () => {
            const data = this.m_form.getValue();
            try {
                const password_hash = md5(data.email + data.password);
                const res = await gl_api.loginWidthPassword(iQuery.ws_stage, iQuery.db_stage, iQuery.email, password_hash);
                gl_app.setAuthToken(res.auth_token);
                gl_app.setHash(iQuery.waiting_hash ? iQuery.waiting_hash : '#', true);
            } catch (e) {
                await gl_api.showError(e);
                gl_app.setHash({ hash: 'login', email: iQuery.email });
            }
        });
    }

    // Gère l'étape de réinitialisation du mot de passe
    onStepResetPassword(iQuery) {
        this.$m_div.find('#title_login').text(_l('Merci de choisir un nouveau mot de passe'));
        this.$m_div.find('#sso_bar').hide();
        this.$m_div.find('#btn_lost_password').hide();
        this.m_form = new MSForm({
            fields: [
                {
                    type: 'fl_email',
                    cols: [12, 12, 12],
                    key: 'email',
                    label: _l("Adresse Email"),
                    readonly: true,
                    css: {
                        'text-transform': 'lowercase'
                    },
                    validation: {
                        required: true,
                    }
                },
                {
                    type: 'fl_password',
                    cols: [12, 12, 12],
                    key: 'password',
                    label: _l("Mot de passe"),
                    validation: {
                        required: true,
                        func: (iInput) => {
                            return this.verifyPassword($(iInput).val());
                        }
                    }
                }
            ]
        });
        const $form = this.m_form.create();
        this.$m_div.find('#login_form').html('');
        this.$m_div.find(".text-divider").hide();
        $form.appendTo(this.$m_div.find('#login_form'));

        const $passwordInput = this.$m_div.find('input[name="password"]');
        const $toggleButton = $('<button type="button" class="btn btn-outline-secondary show-password-btn"><i class="bi bi-eye"></i></button>');

        $toggleButton.insertAfter($passwordInput);

        // Ajouter le gestionnaire d'événement pour le bouton "eye"
        $toggleButton.on('click', () => {
            if ($passwordInput.attr('type') === 'password') {
                $passwordInput.attr('type', 'text');
                $toggleButton.find('i').removeClass('bi-eye').addClass('bi-eye-slash');
            } else {
                $passwordInput.attr('type', 'password');
                $toggleButton.find('i').removeClass('bi-eye-slash').addClass('bi-eye');
            }
        });

        const val = {
            email: iQuery.email,
        };
        this.m_form.setValue(val);
        const $btn = this.$m_div.find('#btn_submit_login');
        $btn.text(_l('valider'));
        this.m_form.on('change', () => {
            if (this.m_form.isValid()) {
                $btn.removeClass('disabled');
            } else {
                $btn.addClass('disabled');
            }
        });
        this.m_form.trigger('change');
        $btn.off().on('click', async () => {
            const data = this.m_form.getValue();
            try {
                if (iQuery.expiration * 1000 < Date.now()) {
                    MSModal.alert('Votre demande a expiré, merci de recommencer votre opération').then(() => {
                        gl_app.setHash({ hash: 'login', email: iQuery.email });
                    });
                    return;
                }

                const password_hash = md5(data.email + data.password);
                const res = await gl_api.resetUserPassword(iQuery.ws_stage, iQuery.email, iQuery.token, password_hash);
                gl_app.setAuthToken(res.auth_token);
                gl_app.setHash('#', true);
            } catch (e) {
                await gl_api.showError(e);
                gl_app.setHash({ hash: 'login', email: iQuery.email });
            }
        });
    }

    onOpen(iQuery) {
        //console.log('onOpen', iQuery);
    }

}

export class PageLoginDeadEnd extends MSAppPage {

    onCreate() {
        // Affiche un message d'attente de validation si l'utilisateur est en attente d'activation de son compte
        $('<h1/>').text(_l('En attente de validation')).appendTo(this.$m_div);
        const html = _h('Un email de confirmation vient de vous être envoyé à l\'adresse {0}. Veuillez cliquer sur le lien présent dans l\'email afin de finaliser votre inscription').format('<span class="email"></span>');
        $('<p/>').html(html).appendTo(this.$m_div);
        $('<button/>').text(_l('Renvoyer l\'email')).appendTo(this.$m_div);
    }

    onOpen(iQuery) {
        // Met à jour l'interface utilisateur lors de l'ouverture de la page de validation
        this.$m_div.find('span.email').text(iQuery.email);
        this.$m_div.find('button').off().on('click', async () => {
            try {
                await gl_api.sendVerificationEmail(iQuery.ws_stage, iQuery.db_stage, iQuery.email, 'https://' + window.location.hostname + '/#login?step=reset_password&reason=create&email={{{email}}}&token={{{token}}}&ws_stage={{{ws_stage}}}&expiration={{{expiration}}}');
                toast.success(_l("Le mail a bien éte envoyé"));
            } catch (e) {
                gl_api.toastError(_l('Impossible d\'envoyer le mail'), e);
            }
        });
    }

}

