import './Forms.css';
import { FormsTheme } from './FormsTheme';

export class MSFormField {

    constructor(iConfig, iParent) {
        this.m_cfig = iConfig;
        this.m_parent = iParent;
        this.m_form = iParent ? iParent.m_form : this;
        this.m_uid = ms_uid();
    }

    $create() {
        this.$m_element = $(this.html());
        return this.$m_element
    }

    onCreated(iFieldConfig) {
        if (iFieldConfig.value) {
            this.setValue(iFieldConfig.value);
        }
    }

    create() {
        console.warn('Form.create is obsolete, please use $create instead');
        return this.$create()
    }

    html() {
        console.error("MSFormField has no implementation for 'html'");
    }

    outerCss() {
        return {};
        //      return { padding: '0.2rem' };
    }

    outer_class() {
        let res = 'col';
        if (Array.isArray(this.m_cfig.cols) && this.m_cfig.cols.length > 0) {
            res = 'col-' + this.m_cfig.cols[0];
            if (this.m_cfig.cols.length > 1) {
                res += ' col-md-' + this.m_cfig.cols[1];
                if (this.m_cfig.cols.length > 2) {
                    res += ' col-lg-' + this.m_cfig.cols[2];
                }
            }
        }
        return res;
    }

    is_value_valid() {
        if (!this.$m_element) {
            return true;
        }
        if (this.$m_input) {
            if (!this.$m_input[0]) {
                console.warn('empty input', this);
                return true;
            }
            if (!this.$m_input[0].reportValidity) {
                return true; // valid
            }

            // reportValidity() generates focus event => infinite loop
            // so we do not trigger chenage event on those focus events.
            MSFormField.s_reporting_validity = true;
            const res = this.$m_input[0].reportValidity();
            MSFormField.s_reporting_validity = false;
            // if (!res) {
            //     console.log(this.m_cfig.key + ' NOT VALID');
            // }
            return res;
        }
        return this.$m_element.find('input:invalid').length + this.$m_element.find('select:invalid').length == 0;
    }

    setValue(iValue) {
        console.error("MSFormField has no implementation for 'setValue'");
    }

    getValue() {
        console.error("MSFormField has no implementation for 'getValue'");
    }

    static validate_func(iInput) {
        try {
            const $div = $(iInput).closest('div.ms.form.element');
            if ($div[0] && $div[0].field) {
                const field = $div[0].field;
                if (field.m_cfig.validation) {
                    if (typeof (field.m_cfig.validation.func) === 'function') {
                        iInput.setCustomValidity(field.m_cfig.validation.func(iInput, field) ?? '');
                    } else {
                        iInput.checkValidity();
                    }
                    //iInput.setCustomValidity("Some strange message");
                    //console.log('after '+iInput.validationMessage, iInput.validity);
                    iInput.reportValidity();
                }
            }
        } catch (e) {
            console.log("error at BoField.validate_by_id " + e);
        }
    };

    validation_attrs() {
        let res = "";
        if (this.m_cfig.validation) {
            ['required'].forEach((prop) => {
                if (this.m_cfig.validation[prop]) {
                    res += prop + ' ';
                }
            });
            ['minlength', 'maxlength', 'min', 'max', 'pattern',].forEach((prop) => {
                if (this.m_cfig.validation[prop]) {
                    res += prop + '="' + this.m_cfig.validation[prop] + '" ';
                }
            });
            //if (typeof (this.m_cfig.validation.func) === 'function') {
            res += ' oninput="MSFormField.validate_func(this)"';
            //}

        }
        return res;
    }

    retrieveValue(ioFormData) {
        if (this.m_cfig.key && this.is_value_valid()) {
            const ar = this.m_cfig.key.split('.')
            let data = ioFormData;
            while (ar.length > 1) {
                if (typeof (data[ar[0]]) != 'object') {
                    data[ar[0]] = {};
                }
                data = data[ar[0]];
                ar.splice(0, 1);
            }
            data[ar[0]] = this.getValue();
        }
    }

    applyValue(iFormData) {
        if (this.m_cfig.key) {
            const ar = this.m_cfig.key.split('.')
            let data = iFormData;
            while (ar.length > 1) {
                if (typeof (data[ar[0]]) != 'object') {
                    return;
                }
                data = data[ar[0]];
                ar.splice(0, 1);
            }
            this.setValue(data[ar[0]]);
        }
    }

    show_if(iBool) {
        if (iBool) {
            this.show();
        } else {
            this.hide();
        }
    };

}

export class MSInputField extends MSFormField {

    $create() {
        const $div = $('<div class="form-group field-container ' + (this.m_cfig.outer_classes ? this.m_cfig.outer_classes : "") + '"></div>');
        this.$m_input = $(this.html()).appendTo($div).find('input');
        if (this.m_cfig.value) {
            this.setValue(this.m_cfig.value)
        }
        //this.$m_input.changed((e) => this.m_form.on_changed(e));
        return $div;
    }

    setValue(iValue) {
        //$('#ms_form_uid-' + this.m_uid).val(iValue);
        if (this.$m_input) {
            this.$m_input.val(iValue);
        }
    }

    getValue() {
        if (this.$m_input) {
            const res = this.$m_input.val();
            if (res && res.length > 0 && this.m_input_type == 'email') {
                return res.toLowerCase();
            }
            return res;
        }
    }

    show() {
        //$('#ms_form_uid-' + this.m_uid).closest("div.field-container").show();
        if (this.$m_element) {
            this.$m_element.closest("div.field-container").show();
        }
    };

    hide() {
        //$('#ms_form_uid-' + this.m_uid).closest("div.field-container").hide();
        if (this.$m_element) {
            this.$m_element.closest("div.field-container").hide();
        }
    };

    help_html() {
        // not implemented yet
        if (false && this.m_cfig.help_text) {
            return '<small class="form-text text-muted">' + htmlent(this.m_cfig.help_text) + '</small>';
        }
        return '';
    };

    labeledHtml(iHtml) {
        if (!this.m_cfig.label) {
            return iHtml;
        }
        return iHtml + '<label for="ms_form_uid-' + this.m_uid + '">' + this.label() + '</label>';
    };

    label() {
        let h = htmlent(this.m_cfig.label);
        if (this.m_cfig.help_text) {
            h += '<i class="far fa-question-circle ml-1" data-toggle="tooltip" data-placement="top" title="' + htmlent(this.m_cfig.help_text) + '"></i>';
        }
        return h;
    };

    html_props() {
        let res = '';
        if (this.m_cfig.html_props) {
            res += ' ' + this.m_cfig.html_props;
        }
        if (this.m_cfig.readonly) {
            res += ' readonly';
        }
        if (this.m_cfig.autofocus) {
            res += ' autofocus';
        }
        if (this.m_cfig.autocomplete) {
            res += ' autocomplete="' + this.m_cfig.autocomplete + '"';
        }
        return res + this.validation_attrs();
    }

    validation_props() {
        return '';
    }
}

export class MSFormFieldContainer extends MSFormField {

    m_fields = [];

    $create() {
        this.$m_element = $('<form class="ms"></form>');
        const $row = this.$createContainer(this.$m_element);
        this.createChildren($row);
        return this.$m_element;
    }

    $createChild(iField) {
        const cfig = iField.m_cfig;
        const $div = $('<div class="ms form element"/>');
        if (cfig.class) {
            $div.addClass(cfig.class);
        }
        const $field = iField.$create();
        iField.$m_element = $div;
        $field.appendTo($div);
        $div[0].field = iField;
        $field[0].field = iField;
        $div.css(iField.outerCss());
        $div.addClass(iField.outer_class());
        return $div;
    }

    $createContainer($iParent) {
        const $res = $("<div/>").addClass("ms form row");
        $res.appendTo($iParent);
        return $res;
    }

    $createRow($iParent) {
        const $res = $("<div/>").addClass("ms form row");
        $res.appendTo($iParent);
        return $res;
    }

    createChildren($iContainer) {
        if (this.m_cfig.fields) {
            this.m_cfig.fields.forEach((field_cfig) => {
                const $row = $("<div/>").addClass("ms form row").appendTo($iContainer);
                const ar = Array.isArray(field_cfig) ? field_cfig : [field_cfig];
                ar.forEach((field_cfig) => {
                    const field = MSForm.createField(field_cfig, this);
                    if (field) {
                        const $field = this.$createChild(field);
                        $field.appendTo($row);
                        field.onCreated(field_cfig);
                        this.m_fields.push(field);
                    }
                });
            });
        }
    }

    // createChildren($iContainer) {
    //     if (this.m_cfig.fields) {
    //         this.m_cfig.fields.forEach((field_cfig) => {
    //             const $row = $("<div/>").addClass("ms form row").appendTo($iContainer);
    //             const ar = Array.isArray(field_cfig) ? field_cfig : [field_cfig];
    //             ar.forEach((field_cfig) => {
    //                 const $div = $('<div class="ms form element"/>');
    //                 if (field_cfig.class) {
    //                     $div.addClass(field_cfig.class);
    //                 }
    //                 const field = MSForm.createField(field_cfig, this);
    //                 if (field) {
    //                     const $field = field.$create();
    //                     field.$m_element = $div;
    //                     $field.appendTo($div);
    //                     $div[0].field = field;
    //                     $div.css(field.outerCss());
    //                     if (field_cfig.css) {
    //                         $div.find('input').css(field_cfig.css);
    //                     }
    //                     $div.appendTo($row);
    //                     $div.addClass(field.outer_class());
    //                     field.onCreated(field_cfig);
    //                     this.m_fields.push(field);
    //                 }
    //             });
    //         });
    //     }
    // }

    outerCss() {
        return {};
    }

    retrieveValue(ioFormData) {
        this.m_fields.forEach((field) => {
            field.retrieveValue(ioFormData);
        })
    }

    applyValue(iFormData) {
        this.m_fields.forEach((field) => {
            field.applyValue(iFormData);
        })
    }

    findByKey(iKey) {
        return this.m_fields.find((field) => field.m_cfig.key == iKey);
    }
}

export class MSForm extends MSFormFieldContainer {

    constructor(iConfig, iParent = null) {
        super(iConfig, iParent);
        this.m_listeners = {};
    }

    create() {
        console.warn('create is obsolete, please use $create instead');
        return this.$create();
    }

    $create() {
        this.$m_element = $('<form class="ms" onsubmit="return false" ></form>');
        super.createChildren(this.$m_element);

        // this.$m_element.find('input').on('valid', (event) => {
        //     console.log('received valid event. Form is ' + this.$m_element.find('input:invalid').length);
        // });
        // this.$m_element.find('input').on('invalid', (event) => {
        //     console.log('received invalid event. Form is ' + this.$m_element.find('input:invalid').length);
        // });
        // this.$m_element.on('invalid', (event) => {
        //     console.log('received MAIN invalid event. Form is ' + this.$m_element.find('input:invalid').length);
        // });

        this.m_current_value = this.getValue();
        this.m_current_validity = this.isValid();

        // button must be updated on each key stroke
        // this.$m_element.find('input,select,textarea').change(() => this.updateButtons());
        this.$m_element.find("input").bind('input', () => { this.updateButtons(); });

        // but the change event must be sent only on focus out
        this.$m_element.find('select, input[type=checkbox]').change((e) => this.onChanged(e));

        this.$m_element.find("input[type=text], input[type=textarea], input[type=email], input[type=password], input[type=tel], input[type=time], input[type=date], input[type=datetime-local], input[type=color]").bind("focusout", (iEvent) => {
            if(this.m_cfig.bind_focus_out || this.m_cfig.bind_focus_out == undefined){
                this.onChanged(iEvent);
            }
        })
        this.$m_element.find("input[type=text], input[type=textarea], input[type=email], input[type=password], input[type=tel], input[type=time], input[type=date], input[type=datetime-local]").bind("keypress", (iEvent) => {
            if (iEvent.keyCode == 13) {
                //console.log("keypress",iEvent)
                this.onChanged(iEvent);
            }
        })

        return this.$m_element;
    }

    onChanged(e) {
        // avoid infinite loop
        if (MSFormField.s_reporting_validity) {
            //console.log("we ignore focusout event generated by report_validity()");
            return;
        }

        //console.log(`trigger onChanged`, e);
        const new_value = this.getValue();
        //console.log(`new value`, new_value);
        if (JSON.stringify(new_value) != JSON.stringify(this.m_current_value)) {
            this.m_current_value = new_value;
            this.trigger('change', e);
        }
        this.updateButtons();
    }

    isValid() {
        //        return this.$m_element.find('input:invalid').filter(":visible").length == 0;
        return this.$m_element.find('input:invalid').length == 0;
    }

    trigger(iWhat, iEvent) {
        //console.log(`trigger ${iWhat}`, iEvent);
        if (this.m_listeners[iWhat]) {
            this.m_listeners[iWhat](iEvent);
        }
        if (this.m_form != this) {
            this.m_form.trigger(iWhat, iEvent);
        }
    }

    setRevertBtn($iBtn) {
        this.$m_revert_btn = $iBtn;
        $iBtn.on('click', () => {
            if (this.m_original_value) {
                this.setValue(this.m_original_value);
            }
        });
        this.updateButtons();
    }

    setValidateBtn($iBtn) {
        this.$m_validate_btn = $iBtn;
        this.updateButtons();
    }

    updateButtons() {
        if (this.m_form != this) {
            //console.log('updateButtons: not me');
            this.m_form.updateButtons();
            return;
        }
        if (MSFormField.s_reporting_validity) {
            //console.log("we ignore focusout event generated by report_validity()");
            return;
        }
        if (this.$m_validate_btn || this.$m_revert_btn) {
            if (!this.isValid()) {
                $(this.$m_validate_btn).prop("disabled", true);
                $(this.$m_revert_btn).prop("disabled", false);
            } else if (!MSForm.isModified(this.m_original_value, this.getValue())) {
                $(this.$m_revert_btn).prop("disabled", true);
                $(this.$m_validate_btn).prop("disabled", true);
            } else {
                //console.log('updateButtons: enable');
                $(this.$m_revert_btn).prop("disabled", false);
                $(this.$m_validate_btn).prop("disabled", false);
            }
        }
    }


    on(iWhat, iFunc) {
        this.m_listeners[iWhat] = iFunc;
    }

    field(iId) {
        return this.m_fields[iId];
    }

    setValue(iValue) {
        this.applyValue(iValue);
        this.m_current_value = this.getValue();
        this.m_original_value = $.extend(this.m_current_value, {});
        //console.log('m_original_value', this.m_original_value)
        this.m_current_validity = this.isValid();
        $(this.$m_validate_btn).prop("disabled", true);
        $(this.$m_revert_btn).prop("disabled", true);
    }

    getValue() {
        let res = {};
        this.retrieveValue(res);
        return res;
    }

    getPatch() {
        const res = this.getValue();
        if (!this.m_original_value) {
            return res;
        }
        return MSForm.calculatePatch(this.m_original_value, res);
    }
}

MSForm.s_field_classes = {};
MSForm.registerFieldClass = (iType, iClass) => {
    MSForm.s_field_classes[iType] = iClass;
};
MSForm.calculatePatch = (iPreviousObject, iNewObjet) => {
    let patch = {};
    for (const [key, value] of Object.entries(iNewObjet)) {
        if (value != null && iPreviousObject != null && typeof value === 'object' && typeof iPreviousObject[key] === 'object') {
            const obj = MSForm.calculatePatch(iPreviousObject[key], value);
            if (Object.entries(obj).length != 0) {
                patch[key] = obj;
            }
        } else if (value !== iPreviousObject[key]) {
            patch[key] = value;
        }
    }
    return patch;
}
MSForm.isModified = (iPreviousObject, iNewObjet) => {
    return JSON.stringify(iPreviousObject) != JSON.stringify(iNewObjet)
}


MSForm.createField = (iConfig, iParent) => {
    const field_class = MSForm.s_field_classes[iConfig.type];
    if (!field_class) {
        console.error(`Unknown field type '${iConfig.type}'`);
        return undefined;
    }
    return new field_class(iConfig, iParent);
}

MSForm.s_theme = new FormsTheme();
MSForm.setTheme = (iTheme) => {
    MSForm.s_theme = iTheme;
};



